import { useEffect, useRef, useState } from "react";
import { modalStore } from "../store/modalsStore";
import { useClickAway } from "@uidotdev/usehooks";
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import i18next, { t } from "i18next";

// Import Swiper styles
import 'swiper/css';
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Facebook, Instagram, MapMarker, Tiktok, Youtube } from "@common/Icons";
import { isValidURL } from "@common/functions";
import { useTranslation } from "react-i18next";
import VideoResponsive from "./VideoResponsive";

const SlideNextButton = ({ children }) => {
    const swiper = useSwiper();

    return (
        <button
            className={"text-white absolute top-0 right-0 z-10 w-12 h-full mx-auto text-center flex items-center justify-center bg-gradient-to-l from-[rgba(0,0,0,.3)] [&>*]:active:scale-150"}
            onClick={() => swiper.slideNext()}
        >{children}</button>
    );
}
const SlidePrevButton = ({ children }) => {
    const swiper = useSwiper();

    return (
        <button
            className={"text-white absolute top-0 left-0 z-10 w-12 h-full mx-auto text-center flex items-center justify-center bg-gradient-to-r from-[rgba(0,0,0,.3)] [&>*]:active:scale-150"}
            onClick={() => swiper.slidePrev()}
        >{children}</button>
    );
}
export default () => {
    const [t] = useTranslation("global");
    const [visible, setVisible] = useState(false);
    const { dataModal, dataId, currentTab, toggleModal, changeTab } = modalStore();
    const [originLink, setOriginLink] = useState("https://www.originbywolf.com");
    const [videoVertical, setVideoVertical] = useState(false);
    const [videoHorizontal, setVideoHorizontal] = useState(false);
    const ref = useClickAway(() => {
        toggleModal(false);
    });
    useEffect(() => {
        
        i18next.language === "en" ? setOriginLink("https://originbywolf.com/en/") : setOriginLink("https://originbywolf.com");
        setVideoVertical(dataModal?.videos?.[i18next.language]?.vertical || t("video_unavailable"));
        setVideoHorizontal(dataModal?.videos?.[i18next.language]?.horizontal || t("video_unavailable"));
        setVisible(true);
        return () => {
            setVisible(false);
        }
    }, []);

    return <>
        <div className={`fixed top-0 left-0 right-0 w-screen h-screen bg-[rgba(0,0,0,0.8)] z-50 overflow-hidden transition-all duration-300 ease-out
            ${visible ? '!opacity-100' : 'opacity-0'}`}>
            <div className="w-full h-screen m:h-full flex items-center justify-center">
                <div ref={ref} className="w-full sm:w-3/4 md:w-2/4 lg:w-1/3 xl:w-96 h-full sm:h-auto shadow-none sm:shadow-[0px_0px_10px_rgba(255,255,255,.4)] bg-black rounded-none sm:rounded-lg overflow-hidden">
                    <div className={`h-[70%] sm:h-[35em] bg-cover bg-center bg-no-repeat relative overflow-hidden bg-black after:bg-[#9B8665] after:w-[60%] after:h-2 after:absolute after:-bottom-2 after:mx-auto after:translate-x-[35%]`} style={currentTab == 'video' ? { background: '#000' } : {}}>
                        {
                            currentTab == 'video' ? (isValidURL(videoVertical) ? <>
                                <VideoResponsive videoVertical={videoVertical} videoHorizontal={videoHorizontal} />
                            </> : <>
                                <div className="h-full flex items-center justify-center">
                                    <p className="text-white text-lg font-bold">{videoVertical}</p>
                                </div>
                            </>) : <>
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    navigation
                                    pagination={{ clickable: true }}
                                    scrollbar={{ draggable: true }}
                                    className="h-full"
                                >
                                    {dataModal && dataModal.galleryImages.map((image, index) => {
                                        return <SwiperSlide key={index} className={`h-full bg-cover bg-center bg-no-repeat`} style={{ backgroundImage: `url('${image}')` }}>

                                        </SwiperSlide>
                                    })}
                                    <SlideNextButton>
                                        <ChevronRight />
                                    </SlideNextButton>
                                    <SlidePrevButton>
                                        <ChevronLeft />
                                    </SlidePrevButton>
                                </Swiper>
                            </>
                        }
                    </div>
                    <div className="h-[30%] sm:h-44 bg-black p-2 px-4 flex flex-col gap-1 justify-around">
                        <div className="bg-white rounded-xl grid grid-cols-3 sm:grid-cols-4 gap-2 items-center p-2">
                            <div className="bg-cover bg-no-repeat bg-center w-auto h-full overflow-hidden rounded-md" style={{
                                backgroundImage: `url(${dataModal?.urlCardImage})`
                            }}>
                            </div>
                            <div className="flex flex-col gap-1 col-span-2 sm:col-span-3">
                                <div className="flex items-center gap-3">
                                    {
                                        dataModal.key == 1 &&
                                        <a href={originLink} target="_blank" className="h-9 w-9 flex items-center justify-center">
                                            <img src="https://zonahuayacan.com.mx/logo_black192.png" className=" w-full" />
                                        </a>
                                    }
                                    {dataModal?.socials?.facebook && <a href={dataModal.socials.facebook} target="_blank" className="h-6 w-6 flex items-end justify-end bg-black rounded-full">
                                        <Facebook className="text-white" />
                                    </a>}
                                    {dataModal?.socials?.instagram && <a href={dataModal.socials.instagram} target="_blank" className="h-6 w-6 flex items-center justify-center bg-black rounded-full">
                                        <Instagram className="text-white" />
                                    </a>}
                                    {dataModal?.socials?.tiktok && <a href={dataModal.socials.tiktok} target="_blank" className="h-6 w-6 flex items-center justify-center bg-black rounded-full">
                                        <Tiktok className='w-4 h-4 text-white' />
                                    </a>}
                                    {dataModal?.socials?.youtube && <a href={dataModal.socials.youtube} target="_blank" className="h-6 w-6 flex items-center justify-center bg-black rounded-full">
                                        <Youtube className="w-4 h-4 text-white" />
                                    </a>}

                                </div>
                                <p className="text-black text-xs flex items-end">
                                    <MapMarker className="text-[#9B8665] w-5" /> <span><span className="text-[#9B8665] font-bold">{dataModal.replace}</span>{dataModal.name.replace(dataModal.replace, '')}</span>
                                </p>
                                <a href={dataModal?.mapLink ? dataModal.mapLink : `https://www.google.com/maps/search/?api=1&query=${dataModal.address}`} target="_blank" className="text-black text-justify text-[.7em] underline flex gap-1 items-end">
                                    {dataModal.address}
                                </a>
                            </div>
                        </div>
                        <div className="flex items-center justify-around">
                            <button onClick={() => changeTab('gallery')}>
                                <img src={`/images/icons/photo-icon${currentTab == 'gallery' ? '1' : '2'}.png`} alt="photo-button" className="w-10" />
                            </button>
                            <button onClick={() => changeTab('video')} >
                                <img src={`/images/icons/video-icon${currentTab == 'video' ? '1' : '2'}.png`} alt="video-button" className="w-10" />
                            </button>
                            <button className="bg-[#9B8665] text-white rounded-lg px-5 py-2" onClick={() => toggleModal(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}