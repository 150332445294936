export const Facebook = ({ className }) => {
    return (
        <svg className={className} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <path fillRule="evenodd" d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z" clipRule="evenodd" />
        </svg>
    );
}
export const Instagram = ({ className }) => {
    return <svg className={className} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 24 24">
        <path fill="currentColor" fillRule="evenodd" d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z" clipRule="evenodd" />
    </svg>;
}
export const Tiktok = ({ className }) => {
    return <svg className={className} viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor">
        <path fill="currentColor" d="M16.656 1.029c1.637-0.025 3.262-0.012 4.886-0.025 0.054 2.031 0.878 3.859 2.189 5.213l-0.002-0.002c1.411 1.271 3.247 2.095 5.271 2.235l0.028 0.002v5.036c-1.912-0.048-3.71-0.489-5.331-1.247l0.082 0.034c-0.784-0.377-1.447-0.764-2.077-1.196l0.052 0.034c-0.012 3.649 0.012 7.298-0.025 10.934-0.103 1.853-0.719 3.543-1.707 4.954l0.020-0.031c-1.652 2.366-4.328 3.919-7.371 4.011l-0.014 0c-0.123 0.006-0.268 0.009-0.414 0.009-1.73 0-3.347-0.482-4.725-1.319l0.040 0.023c-2.508-1.509-4.238-4.091-4.558-7.094l-0.004-0.041c-0.025-0.625-0.037-1.25-0.012-1.862 0.49-4.779 4.494-8.476 9.361-8.476 0.547 0 1.083 0.047 1.604 0.136l-0.056-0.008c0.025 1.849-0.050 3.699-0.050 5.548-0.423-0.153-0.911-0.242-1.42-0.242-1.868 0-3.457 1.194-4.045 2.861l-0.009 0.030c-0.133 0.427-0.21 0.918-0.21 1.426 0 0.206 0.013 0.41 0.037 0.61l-0.002-0.024c0.332 2.046 2.086 3.59 4.201 3.59 0.061 0 0.121-0.001 0.181-0.004l-0.009 0c1.463-0.044 2.733-0.831 3.451-1.994l0.010-0.018c0.267-0.372 0.45-0.822 0.511-1.311l0.001-0.014c0.125-2.237 0.075-4.461 0.087-6.698 0.012-5.036-0.012-10.060 0.025-15.083z"></path>
    </svg>
}
export const Youtube = ({ className }) => {
    return <svg className={className} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 24 24">
        <path fillRule="evenodd" d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z" clipRule="evenodd" />
    </svg>
}
export const Envelope = ({ className }) => {
    return <svg className={className} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="m3.5 5.5 7.893 6.036a1 1 0 0 0 1.214 0L20.5 5.5M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z" />
    </svg>
}
export const MapMarker = ({ className }) => {
    return <svg className={className} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
        <path fillRule="evenodd" d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z" clipRule="evenodd" />
    </svg>
}
export const English = ({ className }) => {
    return <svg height="24" width="24" version="1.1" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 496">
        <path style={{ "fill": "#FFFFFF" }} d="M496,369.6c0,27.2-21.6,48.8-48,48.8H48c-26.4,0-48-21.6-48-48.8V126.4c0-27.2,21.6-43.8,48-43.8h400 c26.4,0,48,16.6,48,43.8V369.6z" />
        <path style={{ "fill": "#CCE4E5" }} d="M448,77.6H48l400,340.8c26.4,0,48-21.6,48-48.8V126.4C496,99.2,474.4,77.6,448,77.6z" />
        <path style={{ "fill": "#AED3D3" }} d="M448,77.6H48l448,137.6v-88.8C496,99.2,474.4,77.6,448,77.6z" />
        <g>
            <path style={{ "fill": "#B22234" }} d="M4.9,391.2c7.5,15.4,22.7,26.2,40.5,27.1c0.9,0,1.7,0.1,2.6,0.1h400.3c0,0,3.5-0.3,4.6-0.7 c1.7-0.6,1.9-2,0.6-3.1l-0.4-0.4L426,391.1H4.9V391.2z" />
            <polygon style={{ "fill": "#B22234" }} points="120.9,131.2 0,131.2 0,157.2 151.4,157.2 	" />
            <polygon style={{ "fill": "#B22234" }} points="181.9,183.2 0,183.2 0,209.2 212.5,209.2 	" />
            <polygon style={{ "fill": "#B22234" }} points="242.8,235.2 0,235.2 0,261.6 273.2,261.6 	" />
            <polygon style={{ "fill": "#B22234" }} points="303.6,287.2 0,287.2 0,313.6 334.8,313.6 	" />
            <polygon style={{ "fill": "#B22234" }} points="373.4,346.3 365,339.2 0,339.2 0,365.6 395.6,365.6 	" />
        </g>
        <path style={{ "fill": "#FFFFFF" }} d="M48,418.4c-0.9,0-1.7,0-2.6-0.1C46.3,418.4,47.1,418.4,48,418.4z" />
        <g>
            <polygon style={{ "fill": "#8C1126" }} points="238.4,131.2 110.9,131.2 141.4,157.2 323.2,157.2 	" />
            <polygon style={{ "fill": "#8C1126" }} points="202.5,209.2 488.5,209.2 403.8,183.2 171.9,183.2 	" />
            <polygon style={{ "fill": "#8C1126" }} points="264,261.6 496,261.6 496,235.2 233,235.2 	" />
            <polygon style={{ "fill": "#8C1126" }} points="325,313.6 496,313.6 496,287.2 294,287.2 	" />
            <polygon style={{ "fill": "#8C1126" }} points="363.4,346.3 386,365.6 496,365.6 496,339.2 355.2,339.2 	" />
        </g>
        <g>
            <polygon style={{ "fill": "#5B0218" }} points="222.5,131.2 286,150.7 307.2,157.2 496,157.2 496,131.2 	" />
            <polygon style={{ "fill": "#5B0218" }} points="392,183.2 476.8,209.2 496,209.2 496,183.2 	" />
            <path style={{ "fill": "#5B0218" }} d="M486.4,97.1c-1.4-1.9-2.9-3.6-4.5-5.3c-8.1-8.2-19.1-13.5-31.3-14.1c-0.8,0-1.7-0.1-2.5-0.1l0,0H48 c-13.1,0-25.5,5.9-34.4,14.7c-3.5,3.7-6.5,7.9-8.7,12.5h75h56.6H491C489.8,102.1,488.2,99.5,486.4,97.1z" />
        </g>
        <path style={{ "fill": "#8C1126" }} d="M491.1,391.2h-75l31.9,27.2C466.8,418.4,483.2,407.4,491.1,391.2z" />
        <path style={{ "fill": "#3C3B6E" }} d="M0,126.4v134.4h215.2V77.6H44.8C20,79.2,0,100,0,126.4z" />
        <polygon style={{ "fill": "#1E1E44" }} points="215.2,220 215.2,77.6 48,77.6 " />
        <polygon points="215.2,128.8 215.2,77.6 48,77.6 " />
        <g>
            <polygon style={{ "fill": "#FFFFFF" }} points="60.8,96 64,85.6 68,96 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="104,96 107.2,85.6 110.4,96 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="146.4,96 150.4,85.6 153.6,96 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="189.6,96 192.8,85.6 196.8,96 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="39.2,114.4 43.2,104 46.4,114.4 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="82.4,114.4 85.6,104 89.6,114.4 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="125.6,114.4 128.8,104 132,114.4 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="168,114.4 171.2,104 175.2,114.4 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="18.4,132.8 21.6,122.4 24.8,132.8 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="60.8,132.8 64,122.4 68,132.8 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="104,132.8 107.2,122.4 110.4,132.8 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="146.4,132.8 150.4,122.4 153.6,132.8 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="189.6,132.8 192.8,122.4 196.8,132.8 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="40,151.2 43.2,140.8 47.2,151.2 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="83.2,151.2 86.4,140.8 89.6,151.2 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="125.6,151.2 129.6,140.8 132.8,151.2 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="168.8,151.2 172,140.8 175.2,151.2 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="18.4,168.8 22.4,158.4 25.6,168.8 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="61.6,168.8 64.8,158.4 68.8,168.8 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="104.8,168.8 108,158.4 111.2,168.8 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="147.2,168.8 151.2,158.4 154.4,168.8 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="190.4,168.8 193.6,158.4 196.8,168.8 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="40,187.2 43.2,176.8 47.2,187.2 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="83.2,187.2 86.4,176.8 89.6,187.2 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="125.6,187.2 129.6,176.8 132.8,187.2 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="168.8,187.2 172,176.8 176,187.2 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="19.2,205.6 22.4,195.2 25.6,205.6 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="62.4,205.6 65.6,195.2 68.8,205.6 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="104.8,205.6 108,195.2 112,205.6 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="148,205.6 151.2,195.2 154.4,205.6 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="190.4,205.6 194.4,195.2 197.6,205.6 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="40,224 43.2,212.8 47.2,224 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="83.2,224 86.4,212.8 89.6,224 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="125.6,224 129.6,212.8 132.8,224 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="168.8,224 172,212.8 175.2,224 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="18.4,241.6 22.4,231.2 25.6,241.6 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="61.6,241.6 64.8,231.2 68,241.6 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="104,241.6 108,231.2 111.2,241.6 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="147.2,241.6 150.4,231.2 154.4,241.6 	" />
            <polygon style={{ "fill": "#FFFFFF" }} points="190.4,241.6 193.6,231.2 196.8,241.6 	" />
        </g>
    </svg>;
}
export const Spanish = ({ className }) => {
    return <svg width="24" height="24" version="1.1" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 496">
        <path style={{ "fill": "#D32030" }} d="M0,304.8v64.8c0,27.2,21.6,48.8,48,48.8h400c26.4,0,48-21.6,48-48.8v-64.8H0z" />
        <path style={{ "fill": "#C11025" }} d="M448,418.4c26.4,0,48-21.6,48-48.8v-64.8H315.2L448,418.4z" />
        <path style={{ "fill": "#D32030" }} d="M448,77.6H48c-26.4,0-48,21.6-48,48.8v64.8h496v-64.8C496,99.2,474.4,77.6,448,77.6z" />
        <path style={{ "fill": "#C11025" }} d="M448,77.6H48l132.8,113.6H496v-64.8C496,99.2,474.4,77.6,448,77.6z" />
        <path style={{ "fill": "#A5001C" }} d="M448,77.6H48l370.4,113.6H496v-64.8C496,99.2,474.4,77.6,448,77.6z" />
        <polygon style={{ "fill": "#FFD034" }} points="0,140.8 0,189.6 0,191.2 0,304.8 0,306.4 0,355.2 496,355.2 496,306.4 496,304.8 496,191.2 496,189.6 496,140.8 " />
        <polygon style={{ "fill": "#FFBC00" }} points="180,189.6 180,189.6 316,306.4 373.6,355.2 496,355.2 496,306.4 496,304.8 496,191.2 496,189.6 496,140.8 122.4,140.8 " />
        <polygon style={{ "fill": "#FFAA00" }} points="255.2,140.8 414.4,189.6 414.4,189.6 496,214.4 496,191.2 496,189.6 496,140.8 " />
        <g>
            <path style={{ "fill": "#A5001C" }} d="M496,369.6c0,27.2-21.6,48.8-48,48.8H48c-26.4,0-48-21.6-48-48.8" />
            <path style={{ "fill": "#A5001C" }} d="M69.6,244.8v33.6c0,16.8,13.6,30.4,30.4,30.4s30.4-13.6,30.4-30.4v-33.6L69.6,244.8L69.6,244.8z" />
            <rect x="137.6" y="249.6" style={{ "fill": "#A5001C" }} width="9.6" height="46.4" />
            <path style={{ "fill": "#A5001C" }} d="M151.2,301.6c-0.8-0.8-1.6-0.8-3.2-0.8c-0.8,0-2.4,0-3.2,0.8s-1.6,0.8-3.2,0.8c-0.8,0-2.4,0-3.2-0.8
                    c-0.8,0-1.6-0.8-3.2-0.8s-2.4,0-3.2,0.8s-1.6,0.8-3.2,0.8v1.6c0.8,0,2.4,0,3.2-0.8c0.8,0,1.6-0.8,3.2-0.8s2.4,0,3.2,0.8
                    s1.6,0.8,3.2,0.8c0.8,0,2.4,0,3.2-0.8c0.8,0,1.6-0.8,3.2-0.8c0.8,0,2.4,0,3.2,0.8s2.4,0.8,3.2,0.8v-1.6
                    C153.6,302.4,152,302.4,151.2,301.6z"/>
            <path style={{ "fill": "#A5001C" }} d="M148.8,304.8c-0.8,0-2.4,0-3.2,0.8s-1.6,0.8-3.2,0.8c-0.8,0-2.4,0-3.2-0.8c-0.8,0-1.6-0.8-3.2-0.8
                    s-2.4,0-3.2,0.8s-1.6,0.8-3.2,0.8v1.6c0.8,0,2.4,0,3.2-0.8c0.8,0,1.6-0.8,3.2-0.8s2.4,0,3.2,0.8s1.6,0.8,3.2,0.8
                    c0.8,0,2.4,0,3.2-0.8c0.8,0,1.6-0.8,3.2-0.8c0.8,0,2.4,0,3.2,0.8s1.6,0.8,3.2,0.8v-1.6c-0.8,0-2.4,0-3.2-0.8
                    C150.4,304.8,149.6,304.8,148.8,304.8z"/>
            <path style={{ "fill": "#A5001C" }} d="M148.8,308c-0.8,0-2.4,0-3.2,0.8s-1.6,0.8-3.2,0.8c-0.8,0-2.4,0-3.2-0.8c-0.8,0-1.6-0.8-3.2-0.8
                    s-2.4,0-3.2,0.8s-1.6,0.8-3.2,0.8v1.6c0.8,0,2.4,0,3.2-0.8c0.8,0,1.6-0.8,3.2-0.8s2.4,0,3.2,0.8s1.6,0.8,3.2,0.8
                    c0.8,0,2.4,0,3.2-0.8c0.8,0,1.6-0.8,3.2-0.8c0.8,0,2.4,0,3.2,0.8s1.6,0.8,3.2,0.8v-1.6c-0.8,0-2.4,0-3.2-0.8
                    C150.4,308.8,149.6,308,148.8,308z"/>
            <path style={{ "fill": "#A5001C" }} d="M148.8,296.8c0,0.8-0.8,1.6-1.6,1.6h-10.4c-0.8,0-1.6-0.8-1.6-1.6l0,0c0-0.8,0.8-1.6,1.6-1.6h10.4
                    C148,295.2,148.8,296,148.8,296.8L148.8,296.8z"/>
            <path style={{ "fill": "#A5001C" }} d="M148.8,248.8c0,0.8-0.8,1.6-1.6,1.6h-10.4c-0.8,0-1.6-0.8-1.6-1.6l0,0c0-0.8,0.8-1.6,1.6-1.6h10.4
                    C148,248,148.8,248,148.8,248.8L148.8,248.8z"/>
        </g>
        <g>
            <rect x="53.6" y="249.6" style={{ "fill": "#D32030" }} width="9.6" height="46.4" />
            <path style={{ "fill": "#D32030" }} d="M67.2,301.6c-0.8-0.8-1.6-0.8-3.2-0.8c-0.8,0-2.4,0-3.2,0.8c-0.8,0.8-1.6,0.8-3.2,0.8
                    c-0.8,0-2.4,0-3.2-0.8c-0.8,0-1.6-0.8-3.2-0.8c-0.8,0-2.4,0-3.2,0.8s-1.6,0.8-3.2,0.8v1.6c0.8,0,2.4,0,3.2-0.8
                    c0.8,0,1.6-0.8,3.2-0.8c0.8,0,2.4,0,3.2,0.8c1.6,0.8,2.4,0.8,4,0.8c0.8,0,2.4,0,3.2-0.8c0.8,0,1.6-0.8,3.2-0.8c0.8,0,2.4,0,3.2,0.8
                    c0,0.8,0.8,0.8,2.4,0.8v-1.6C68.8,302.4,68,302.4,67.2,301.6z"/>
            <path style={{ "fill": "#D32030" }} d="M64,304.8c-0.8,0-2.4,0-3.2,0.8c-0.8,0.8-1.6,0.8-3.2,0.8c-0.8,0-2.4,0-3.2-0.8
                    c-0.8,0-1.6-0.8-3.2-0.8c-0.8,0-2.4,0-3.2,0.8s-1.6,0.8-3.2,0.8v1.6c0.8,0,2.4,0,3.2-0.8c0.8,0,1.6-0.8,3.2-0.8
                    c0.8,0,2.4,0,3.2,0.8s1.6,0.8,3.2,0.8c0.8,0,2.4,0,3.2-0.8c0.8,0,1.6-0.8,3.2-0.8c0.8,0,2.4,0,3.2,0.8c0.8,0.8,1.6,0.8,3.2,0.8
                    v-1.6c-0.8,0-2.4,0-3.2-0.8S65.6,304.8,64,304.8z"/>
            <path style={{ "fill": "#D32030" }} d="M64,308c-0.8,0-2.4,0-3.2,0.8c-0.8,0.8-1.6,0.8-3.2,0.8c-0.8,0-2.4,0-3.2-0.8
                    c-0.8,0-1.6-0.8-3.2-0.8c-0.8,0-2.4,0-3.2,0.8s-1.6,0.8-3.2,0.8v1.6c0.8,0,2.4,0,3.2-0.8c0.8,0,1.6-0.8,3.2-0.8
                    c0.8,0,2.4,0,3.2,0.8s1.6,0.8,3.2,0.8c0.8,0,2.4,0,3.2-0.8c0.8,0,1.6-0.8,3.2-0.8c0.8,0,2.4,0,3.2,0.8c0.8,0.8,1.6,0.8,3.2,0.8
                    v-1.6c-0.8,0-2.4,0-3.2-0.8C66.4,308.8,65.6,308,64,308z"/>
            <path style={{ "fill": "#D32030" }} d="M64.8,296.8c0,0.8-0.8,1.6-1.6,1.6H52.8c-0.8,0-1.6-0.8-1.6-1.6l0,0c0-0.8,0.8-1.6,1.6-1.6h10.4
                    C64,295.2,64.8,296,64.8,296.8L64.8,296.8z"/>
            <path style={{ "fill": "#D32030" }} d="M64.8,248.8c0,0.8-0.8,1.6-1.6,1.6H52.8c-0.8,0-1.6-0.8-1.6-1.6l0,0c0.8-0.8,1.6-0.8,1.6-0.8h10.4
                    C64,248,64.8,248,64.8,248.8L64.8,248.8z"/>
        </g>
        <g>
            <path style={{ "fill": "#A5001C" }} d="M124,238.4c0-1.6-0.8-2.4-2.4-2.4l0,0c0-1.6-9.6-2.4-21.6-2.4s-21.6,0.8-21.6,2.4l0,0
                    c-0.8,0-1.6,1.6-1.6,2.4c0,0.8,0.8,2.4,2.4,2.4c0,1.6,9.6,2.4,21.6,2.4s21.6-0.8,21.6-2.4C123.2,240.8,124,240,124,238.4z"/>
            <path style={{ "fill": "#A5001C" }} d="M137.6,217.6C137.6,217.6,136.8,217.6,137.6,217.6L137.6,217.6L137.6,217.6l0.8-0.8l0,0
                    c0.8-1.6,1.6-3.2,1.6-4.8c0-3.2-2.4-6.4-4.8-8l0.8,0.8c-2.4-1.6-6.4-3.2-10.4-3.2c-0.8,0-1.6,0-3.2,0c0,0,0,0-0.8,0
                    c0,0-0.8,0-0.8,0.8c0,0,0,0-0.8,0c0,0,0,0,0,0.8l0,0c-0.8-0.8-1.6-1.6-2.4-2.4l0,0c-1.6-1.6-4.8-3.2-8-3.2c-2.4,0-4.8,0.8-7.2,1.6
                    c0,0.8,0,0.8,0,1.6c1.6-0.8,4-1.6,6.4-1.6c4.8,0,9.6,2.4,9.6,6.4v0.8c0,1.6-0.8,2.4-1.6,3.2c-2.4,0-4-0.8-5.6-1.6
                    c-2.4-0.8-5.6-1.6-8.8-1.6c0-0.8,0-1.6,0-2.4c0,0,0,0,0-0.8c0,0,0,0,0-0.8c0-1.6,0-2.4,0-3.2s-1.6-1.6-2.4-1.6
                    c1.6,0,2.4-0.8,3.2-1.6l0,0l0,0c0,0,0-0.8,0-1.6l0,0c0-1.6-0.8-2.4-2.4-3.2v-2.4h1.6v-1.6h-1.6v-1.6h-1.6v1.6h-1.6v1.6h1.6v2.4
                    c-1.6,0-2.4,1.6-2.4,3.2c0,0.8,0,0.8,0,1.6l0,0c0.8,0.8,1.6,1.6,2.4,1.6c-0.8,0-1.6,0.8-1.6,0.8s0,0,0-0.8s0-0.8-0.8-0.8
                    s-0.8,0-0.8,0.8s0,0.8,0.8,0.8h0.8l-0.8,0.8l0,0c-1.6-0.8-4-1.6-7.2-1.6s-6.4,0.8-8,3.2l0,0c-0.8,0.8-2.4,1.6-2.4,2.4l0,0
                    c0,0,0,0,0-0.8c0,0,0,0-0.8,0c0,0,0-0.8-0.8-0.8h-0.8c-0.8,0-1.6,0-3.2,0c-4,0-8,1.6-10.4,3.2L64,204c-3.2,1.6-4.8,4.8-4.8,8
                    c0,1.6,0.8,4,1.6,4.8l0,0l0.8,0.8l0,0c-0.8,0-1.6,0.8-1.6,2.4c0,0,1.6,0,2.4,0.8s1.6,2.4,1.6,2.4l0,0c0,0.8,0,0.8,0,1.6
                    c0.8,0.8,0.8,0.8,1.6,0c0.8,0.8,1.6,2.4,3.2,3.2c0.8,0.8,2.4,0.8,3.2,0.8c0,0,0,0,0.8,0l0,0H72c-0.8,0-0.8,0.8-0.8,0.8
                    c0,0.8,0.8,0.8,0.8,0.8l0,0c0,0,0.8,0,1.6,0.8l0.8,0.8l1.6,0.8l0,0c0,0.8,0,1.6,0,1.6c6.4-1.6,14.4-3.2,24-3.2s18.4,1.6,24,3.2
                    c-0.8,0-0.8-0.8,0-1.6l0,0l1.6-0.8l-0.8-0.8c0.8-0.8,1.6-0.8,1.6-0.8l0,0c0.8,0,0.8,0,0.8-0.8s0-0.8-0.8-0.8s-0.8,0-1.6,0l0,0
                    l0.8,0.8c0.8,0,2.4,0,3.2-0.8c1.6-0.8,2.4-2.4,3.2-3.2c0.8,0,0.8,0,1.6,0c0.8-0.8,0.8-1.6,0-1.6l0,0c0,0,0.8-1.6,1.6-2.4
                    c0.8-0.8,2.4-0.8,2.4-0.8C140,219.2,138.4,217.6,137.6,217.6z M78.4,204L78.4,204c-0.8,0-0.8,0-0.8,0.8s0,0.8,0.8,0.8v0.8
                    c0,0-0.8,0-0.8,0.8s0,0.8,0.8,0.8l0,0v0.8c0,0,0,0,0,0.8c-0.8,0-1.6,0-2.4,0c-2.4,0-4.8,0.8-8,1.6c-1.6,0.8-2.4,1.6-4,1.6
                    c-0.8-0.8-0.8-1.6-0.8-3.2c0-0.8,0-1.6,0.8-2.4c1.6-3.2,6.4-4.8,12-4.8c1.6,0,2.4,0,3.2,0l0,0l0,0l0,0l0,0
                    C79.2,203.2,79.2,203.2,78.4,204z M97.6,202.4C97.6,202.4,96.8,203.2,97.6,202.4c0,1.6,0,2.4,0,3.2c-4,0-7.2,0.8-9.6,1.6
                    c-1.6,0.8-3.2,0.8-5.6,1.6c-0.8-0.8-1.6-2.4-1.6-3.2v-0.8c0-3.2,4-6.4,9.6-6.4c2.4,0,4.8,0.8,6.4,1.6v-0.8
                    C97.6,200,97.6,200.8,97.6,202.4C96.8,202.4,97.6,202.4,97.6,202.4z M138.4,207.2c0-0.8-0.8-1.6-1.6-2.4
                    C137.6,205.6,137.6,206.4,138.4,207.2z M136,212.8c-0.8-0.8-2.4-1.6-4-2.4c-3.2-1.6-6.4-1.6-8-1.6c-0.8,0-1.6,0-1.6,0l0,0
                    c0,0,0-0.8-0.8-0.8v-0.8l0,0c0.8,0,0.8,0,0.8-0.8c0,0,0-0.8-0.8-0.8v-0.8c0.8,0,0.8,0,0.8-0.8s0-0.8-0.8-0.8l0,0c0,0,0-0.8-0.8-0.8
                    l0,0l0,0l0,0l0,0c0.8,0,2.4,0,3.2,0c5.6,0,10.4,2.4,12,4.8c0,0.8,0.8,1.6,0.8,2.4C137.6,211.2,136.8,212,136,212.8z"/>
            <ellipse style={{ "fill": "#A5001C" }} cx="140" cy="217.6" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="140.8" cy="215.2" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="141.6" cy="213.6" rx="0.8" ry="0.8" />
            <circle style={{ "fill": "#A5001C" }} cx="141.6" cy="210.4" r="0.8" />
            <circle style={{ "fill": "#A5001C" }} cx="140.8" cy="208" r="0.8" />
            <path style={{ "fill": "#A5001C" }} d="M140,206.4c0.8,0,0.8,0,0.8-0.8s0-0.8-0.8-0.8s-0.8,0-0.8,0.8S139.2,206.4,140,206.4z" />
            <ellipse style={{ "fill": "#A5001C" }} cx="137.6" cy="204" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="136" cy="202.4" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="133.6" cy="201.6" rx="0.8" ry="0.8" />
            <circle style={{ "fill": "#A5001C" }} cx="131.2" cy="200" r="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="128" cy="200" rx="0.8" ry="0.8" />
            <path style={{ "fill": "#A5001C" }} d="M125.6,200.8c0.8,0,0.8,0,0.8-0.8s-0.8-0.8-0.8-0.8c-0.8,0-0.8,0-0.8,0.8
C124.8,200,124.8,200.8,125.6,200.8z"/>
            <circle style={{ "fill": "#A5001C" }} cx="123.2" cy="200" r="0.8" />
            <circle style={{ "fill": "#A5001C" }} cx="120.8" cy="200" r="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="118.4" cy="198.4" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="116" cy="196.8" rx="0.8" ry="0.8" />
            <circle style={{ "fill": "#A5001C" }} cx="113.6" cy="196" r="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="111.2" cy="196" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="108.8" cy="196" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="105.6" cy="196" rx="0.8" ry="0.8" />
            <circle style={{ "fill": "#A5001C" }} cx="103.2" cy="196.8" r="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="60.8" cy="217.6" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="60" cy="215.2" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="59.2" cy="213.6" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="59.2" cy="210.4" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="60" cy="208" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="60.8" cy="205.6" rx="0.8" ry="0.8" />
            <circle style={{ "fill": "#A5001C" }} cx="62.4" cy="204" r="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="64.8" cy="202.4" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="67.2" cy="201.6" rx="0.8" ry="0.8" />
            <circle style={{ "fill": "#A5001C" }} cx="69.6" cy="200" r="0.8" />
            <circle style={{ "fill": "#A5001C" }} cx="72.8" cy="200" r="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="75.2" cy="200" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="77.6" cy="200" rx="0.8" ry="0.8" />
            <circle style={{ "fill": "#A5001C" }} cx="80" cy="200" r="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="82.4" cy="198.4" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="84.8" cy="196.8" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="87.2" cy="196" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="89.6" cy="196" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="92" cy="196" rx="0.8" ry="0.8" />
            <ellipse style={{ "fill": "#A5001C" }} cx="95.2" cy="196" rx="0.8" ry="0.8" />
        </g>
        <g>
            <path style={{ "fill": "#D32030" }} d="M64,247.2C64,246.4,64,246.4,64,247.2L64,247.2c-0.8-0.8-3.2-0.8-5.6-0.8c-3.2,0-5.6,0-5.6,0.8l0,0
                    c0,0,0,0,0,0.8c0,0,0,0.8,0.8,0.8c0,0,2.4,0.8,5.6,0.8S63.2,248,64,247.2L64,247.2z"/>
            <path style={{ "fill": "#D32030" }} d="M67.2,241.6L67.2,241.6L67.2,241.6L67.2,241.6L67.2,241.6L67.2,241.6c0.8-0.8,0.8-0.8,0.8-1.6
                    s-0.8-1.6-1.6-1.6l0,0c-0.8-0.8-1.6-0.8-2.4-0.8h-0.8l0,0l0,0l0,0l0,0l0,0c0,0,0-0.8-0.8-0.8l0,0c-0.8-0.8-0.8-0.8-2.4-0.8
                    c-0.8,0-1.6,0-1.6,0.8l0,0c0.8,0,0.8-0.8,1.6-0.8c1.6,0,2.4,0.8,2.4,1.6l0,0v0.8c-0.8,0-0.8,0-1.6,0c-0.8,0-1.6,0-2.4-0.8
                    c0,0,0,0,0-0.8l0,0l0,0V236c0,0,0-0.8-0.8-0.8c0,0,0.8,0,0.8-0.8l0,0l0,0l0,0l0,0c0,0,0-0.8-0.8-0.8v-0.8h0.8V232h0v-0.8l0,0v0.8
                    h-0.8v0.8h0.8v0.8c0,0-0.8,0-0.8,0.8l0,0l0,0c0,0,0,0.8,0.8,0.8c0,0,0,0-0.8,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c-0.8,0-0.8-0.8-1.6-0.8
                    c-0.8,0-1.6,0-2.4,0.8l0,0c0,0-0.8,0-0.8,0.8l0,0l0,0l0,0l0,0l0,0H52c-0.8,0-1.6,0-2.4,0.8l0,0c-0.8,0.8-1.6,0.8-1.6,1.6
                    s0,0.8,0.8,1.6l0,0l0,0l0,0c0,0-0.8,0-0.8,0.8h0.8l0.8,0.8l0,0c0,0,0,0,0,0.8l0,0c0,0,0,0.8,0.8,0.8h0.8l0,0l0,0l0,0l0,0l0,0l0,0
                    l0,0l0,0l0,0l0,0c0,0,0,0,0,0.8c1.6-0.8,3.2-0.8,5.6-0.8c2.4,0,4.8,0,5.6,0.8c0,0,0,0,0-0.8l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
                    l0,0h0.8l0.8-0.8l0,0c0,0,0,0,0-0.8l0,0C66.4,243.2,67.2,242.4,67.2,241.6C67.2,242.4,68,242.4,67.2,241.6
                    C68,242.4,67.2,241.6,67.2,241.6z M52.8,238.4L52.8,238.4L52.8,238.4C52.8,238.4,52.8,239.2,52.8,238.4
                    C52.8,239.2,52.8,239.2,52.8,238.4C52.8,239.2,52.8,239.2,52.8,238.4c0,0.8,0,0.8,0,0.8l0,0l0,0l0,0c0,0,0,0-0.8,0s-1.6,0-1.6,0.8
                    c0,0-0.8,0-0.8,0.8V240v-0.8c0-0.8,1.6-1.6,3.2-1.6C52.8,238.4,52.8,238.4,52.8,238.4L52.8,238.4L52.8,238.4L52.8,238.4L52.8,238.4
                    L52.8,238.4z M57.6,238.4L57.6,238.4L57.6,238.4c-0.8,0.8-1.6,0.8-2.4,0.8c0,0-0.8,0-1.6,0v-0.8l0,0c0-0.8,0.8-1.6,2.4-1.6
                    C56.8,237.6,56.8,237.6,57.6,238.4C57.6,237.6,57.6,237.6,57.6,238.4C57.6,237.6,57.6,237.6,57.6,238.4L57.6,238.4z M67.2,239.2
                    L67.2,239.2L67.2,239.2z M67.2,240.8c0,0-0.8,0-0.8-0.8c-0.8,0-1.6,0-2.4,0c0,0,0,0-0.8,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
                    l0,0l0,0l0,0l0,0l0,0H64c1.6,0,2.4,0.8,3.2,1.6C67.2,239.2,67.2,240,67.2,240.8C67.2,240,67.2,240.8,67.2,240.8z"/>
        </g>
        <g>
            <path style={{ "fill": "#A5001C" }} d="M68,239.2L68,239.2L68,239.2C68,239.2,67.2,239.2,68,239.2C67.2,239.2,68,239.2,68,239.2z" />
            <path style={{ "fill": "#A5001C" }} d="M148,247.2C148,246.4,148,246.4,148,247.2L148,247.2c-0.8-0.8-3.2-0.8-5.6-0.8c-3.2,0-5.6,0-5.6,0.8
                    l0,0c0,0,0,0,0,0.8c0,0,0,0.8,0.8,0.8c0,0,2.4,0.8,5.6,0.8C145.6,248,148,248,148,247.2L148,247.2z"/>
            <path style={{ "fill": "#A5001C" }} d="M151.2,241.6L151.2,241.6L151.2,241.6L151.2,241.6L151.2,241.6L151.2,241.6c0.8,0,0.8-0.8,0.8-1.6
                    s-0.8-1.6-1.6-1.6l0,0c-0.8-0.8-1.6-0.8-2.4-0.8h-0.8l0,0l0,0l0,0l0,0l0,0c0,0,0-0.8-0.8-0.8l0,0c-0.8-0.8-0.8-0.8-2.4-0.8
                    c-0.8,0-1.6,0-1.6,0.8l0,0c0.8,0,0.8-0.8,1.6-0.8c1.6,0,2.4,0.8,2.4,1.6l0,0v0.8c-0.8,0-0.8,0-1.6,0s-1.6,0-2.4-0.8c0,0,0,0,0-0.8
                    l0,0l0,0V236c0,0,0-0.8-0.8-0.8c0,0,0.8,0,0.8-0.8l0,0l0,0l0,0l0,0c0,0,0-0.8-0.8-0.8v-0.8h0.8V232h-0.8v-0.8h-0.8v0.8H140v0.8h0.8
                    v0.8c0,0-0.8,0-0.8,0.8l0,0l0,0c0,0,0,0.8,0.8,0.8c0,0,0,0-0.8,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c-0.8,0-0.8-0.8-1.6-0.8
                    s-1.6,0-2.4,0.8l0,0c0,0-0.8,0-0.8,0.8l0,0l0,0l0,0l0,0l0,0h-0.8c-0.8,0-1.6,0-2.4,0.8l0,0c-0.8,0.8-1.6,0.8-1.6,1.6s0,0.8,0.8,1.6
                    l0,0l0,0l0,0c0,0-0.8,0-0.8,0.8c0,0,0,0,0.8,0l0.8,0.8l0,0c0,0,0,0,0,0.8l0,0c0,0,0,0.8,0.8,0.8h0.8l0,0l0,0l0,0l0,0l0,0l0,0l0,0
                    l0,0l0,0l0,0c0,0,0,0,0,0.8c1.6-0.8,3.2-0.8,5.6-0.8c2.4,0,4.8,0,5.6,0.8c0,0,0,0,0-0.8l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
                    h0.8l0.8-0.8l0,0c0,0,0,0,0-0.8l0,0C151.2,243.2,151.2,242.4,151.2,241.6C151.2,242.4,152,242.4,151.2,241.6
                    C152,242.4,152,241.6,151.2,241.6z M137.6,238.4L137.6,238.4C136.8,238.4,136.8,238.4,137.6,238.4
                    C136.8,238.4,136.8,239.2,137.6,238.4C136.8,239.2,136.8,239.2,137.6,238.4C136.8,239.2,136.8,239.2,137.6,238.4c0,0.8,0,0.8,0,0.8
                    l0,0l0,0l0,0c0,0,0,0-0.8,0s-1.6,0-1.6,0.8c0,0-0.8,0-0.8,0.8V240v-0.8c0-0.8,1.6-1.6,3.2-1.6C136.8,238.4,136.8,238.4,137.6,238.4
                    L137.6,238.4L137.6,238.4L137.6,238.4L137.6,238.4L137.6,238.4z M141.6,238.4L141.6,238.4L141.6,238.4c-0.8,0.8-1.6,0.8-2.4,0.8
                    c0,0-0.8,0-1.6,0v-0.8l0,0c0-0.8,0.8-1.6,2.4-1.6C140.8,237.6,141.6,237.6,141.6,238.4C141.6,237.6,141.6,237.6,141.6,238.4
                    C141.6,237.6,141.6,237.6,141.6,238.4L141.6,238.4z M152,239.2C151.2,239.2,151.2,239.2,152,239.2
                    C151.2,239.2,151.2,239.2,152,239.2z M151.2,240.8c0,0-0.8,0-0.8-0.8c-0.8,0-1.6-0.8-1.6-0.8s0,0-0.8,0l0,0l0,0l0,0l0,0l0,0l0,0
                    l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0h0.8c1.6,0,2.4,0.8,3.2,1.6C151.2,239.2,151.2,240,151.2,240.8
                    C151.2,240,151.2,240.8,151.2,240.8z"/>
        </g>
        <g>
            <path style={{ "fill": "#D32030" }} d="M100,231.2v-46.4h-0.8v1.6h-1.6v1.6h1.6v2.4c-1.6,0-2.4,1.6-2.4,3.2c0,0.8,0,0.8,0,1.6l0,0
                    c0.8,0.8,1.6,1.6,2.4,1.6c-0.8,0-1.6,0.8-1.6,0.8s0,0,0-0.8s0-0.8-0.8-0.8s-0.8,0-0.8,0.8s0,0.8,0.8,0.8h0.8l-0.8,0.8l0,0
                    c-1.6-0.8-4-1.6-7.2-1.6s-6.4,0.8-8,3.2l0,0c-0.8,0.8-2.4,1.6-2.4,2.4l0,0c0,0,0,0,0-0.8c0,0,0,0-0.8,0c0,0,0-0.8-0.8-0.8h-0.8
                    c-0.8,0-1.6,0-3.2,0c-4,0-8,1.6-10.4,3.2l0.8-0.8c-3.2,1.6-4.8,4.8-4.8,8c0,1.6,0.8,4,1.6,4.8l0,0l0.8,0.8l0,0
                    c-0.8,0-1.6,0.8-1.6,2.4c0,0,1.6,0,2.4,0.8s1.6,2.4,1.6,2.4l0,0c0,0.8,0,0.8,0,1.6c0.8,0.8,0.8,0.8,1.6,0c0.8,0.8,1.6,2.4,3.2,3.2
                    c0.8,0.8,2.4,0.8,3.2,0.8c0,0,0,0,0.8,0l0,0H72c-0.8,0-0.8,0.8-0.8,0.8c0,0.8,0.8,0.8,0.8,0.8l0,0c0,0,0.8,0,1.6,0.8l0.8,1.6
                    l1.6,0.8l0,0c0,0.8,0,1.6,0,1.6C82.4,232,91.2,231.2,100,231.2z M80.8,204.8c0-3.2,4-6.4,9.6-6.4c2.4,0,4.8,0.8,6.4,1.6v-0.8
                    c0,0.8,0,1.6,0,2.4c0,0,0,0,0,0.8c0,0,0,0,0,0.8s0,1.6,0,2.4c-4,0-7.2,0.8-9.6,1.6c-1.6,0.8-3.2,0.8-5.6,1.6
                    C81.6,208,80.8,206.4,80.8,204.8L80.8,204.8z M76,208.8c-2.4,0-4.8,0.8-8,1.6c-1.6,0.8-2.4,1.6-4,1.6c-0.8-0.8-0.8-1.6-0.8-3.2
                    c0-0.8,0-1.6,0.8-2.4c1.6-3.2,6.4-4.8,12-4.8c1.6,0,2.4,0,3.2,0l0,0l0,0l0,0l0,0c0,0,0,0.8-0.8,0.8l0,0c-0.8,0-0.8,0-0.8,0.8
                    s0,0.8,0.8,0.8v0.8c0,0-0.8,0-0.8,0.8s0,0.8,0.8,0.8l0,0v0.8c0,0,0,0,0,0.8C77.6,208.8,76.8,208.8,76,208.8z"/>
            <path style={{ "fill": "#D32030" }} d="M100,233.6L100,233.6c-12.8,0-22.4,0.8-22.4,2.4l0,0c-0.8,0-1.6,1.6-1.6,2.4c0,0.8,0.8,2.4,2.4,2.4 c0,1.6,9.6,2.4,21.6,2.4V233.6z" />
            <path style={{ "fill": "#D32030" }} d="M100,244.8H69.6v33.6c0,16.8,13.6,30.4,30.4,30.4V244.8z" />
        </g>
    </svg>
}