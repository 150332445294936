import { English, Spanish } from "@common/Icons";
import { useTranslation } from "react-i18next";

const LaguangeSelect = ({callback}) => {
    const [t, i18next] = useTranslation("global");
    const handleChangeLang = (lang) => {
        if(lang==i18next.language) return;
        i18next.changeLanguage(lang);
        if(callback) callback(false);
    }
    return <>
        <div className="flex gap-4 justify-center">
            <button className={`text-white flex items-center gap-1 ${i18next.language == "en"?"opacity-50 cursor-default":"opacity-110"}`} onClick={() => handleChangeLang('en')}>
                <English />
                <span>EN</span>
            </button>
            <button className={`text-white flex items-center gap-1 ${i18next.language == "es"?"opacity-50 cursor-default":"opacity-110"}`} onClick={() => handleChangeLang('es')}>
                <Spanish />
                <span>ES</span>
            </button>
        </div>
    </>
}

export default LaguangeSelect;