import { useEffect, useState } from "react";
import LaguangeSelect from "./LanguageSelect";
import { useTranslation } from "react-i18next";
import { useClickAway } from "@uidotdev/usehooks";

const LanguageModal = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [t] = useTranslation("global");
    const ref = useClickAway(() => {
        setIsVisible(false);
    });

    useEffect(()=>{
        setTimeout(() => {
            setIsVisible(true);
        }, 500);
    }, []);

    return <div className="fixed w-screen h-screen bg-[rgba(0,0,0,.8)] top-0 left-0 right-0 z-50" style={{
        opacity: !isVisible ? "0" : "1",
        transition: "all .2s",
        visibility: !isVisible ? "hidden" : "visible",
    }}>
        <div className="w-full h-full flex justify-center items-center">
            <div ref={ref} className="bg-[#A78E64] rounded-3xl relative p-8 shadow-[0px_0px_15px_rgba(0,0,0,.25)] w-[80%] lg:w-[60%] xl:w-[40%]">
                <button className="absolute top-4 right-4 w-5 h-5 shadow-[0px_0px_5px_rgba(0,0,0,.4)] active:shadow-none rounded-full bg-white" onClick={()=>setIsVisible(!isVisible)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
                </button>
                <div>
                    <p className="text-center">
                        <span className="font-bold">{t("language_modal.bold")}</span> {t('language_modal.normal')}
                    </p>
                </div>
                <div className="mt-6">
                    <LaguangeSelect />
                </div>
            </div>
        </div>
    </div>
}

export default LanguageModal;