import Header from './components/ui/Header';
import Footer from './components/ui/Footer';
import Map from './components/Map';
import './App.css';
import Modal from './components/Modal';
import { modalStore } from './store/modalsStore';
import { isMobile } from 'react-device-detect';
import MobileHome from './components/MobileHome';
import { useRef, useState, useEffect } from 'react';
import Menu from './components/ui/Menu';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from "@uidotdev/usehooks";
import LanguageModal from './components/LanguageModal';

function App() {
  const [t, i18next] = useTranslation("global");
  const [lang, savelang] = useLocalStorage("lang");
  const [showMobileHome, setShowMobileHome] = useState(isMobile);
  const { isVisible } = modalStore();
  const mapRef = useRef();
  const goto = () => {
    setShowMobileHome(false);
  }
  const mapMoveTo = (long, lat, zoom = 18) => {
    mapRef.current.flyTo({
      center: [long, lat],
      zoom,
      offset: [0, -100],
    });
  }

  useEffect(() => {
    i18next.changeLanguage(lang)
  }, []);

  useEffect(() => {
    savelang(i18next.language);
  }, [i18next.language]);
  return (
    <div className="App">
      {
        showMobileHome ? <MobileHome goto={goto} /> :
          <div className='w-full flex-wrap bg-black flex flex-col h-auto mx-auto overflow-x-hidden'>
            <Header />
            <div className="body relative h-[36em] md:h-[46em] border-t-0 md:border-t-4 md:border-t-[#9B8665] after:block after:md:hidden after:absolute after:h-1.5 after:bg-[#9B8665] after:w-[60%] after:mx-auto after:top-0 after:left-0 after:right-0">
              <div className='w-12 hidden md:block absolute -top-9 bg-[#D9D9D9] rounded-full z-[50] mx-auto left-0 border-[#9B8665] border-2 p-2 right-0'>
                <img src="https://zonahuayacan.com.mx/images/frame.png" alt="utils" className='w-full h-full' />
              </div>

              <div className='flex h-full relative'>
                <div className='w-2/3 lg:w-2/4 xl:w-1/3 px-6 bg-[#9B8665] h-full hidden md:block relative'>
                  <div className="h-[75%] flex flex-col">
                    <div className='flex flex-1 flex-col justify-end relative'>
                      <span className='w-1.5 h-[102%] bg-black absolute -top-4 left-0' />
                      <div className='bg-black mb-7 px-8 py-2 rounded-r-2xl w-[110%] -ml-10 flex items-center justify-start'>
                        <p className='ml-[15%] text-white text-left'>{t("left.title.normal")}<span className='block font-extrabold text-[#9B8665]'>{t("left.title.bold")}</span></p>
                      </div>
                      <p className='ml-[10%] text-3xl font-bold text-justify w-full uppercase'>Huayacán</p>
                      <p className='ml-[10%] font-semibold mb-7 uppercase'>{t("left.subtitle")}</p>
                      <p className='ml-[10%] text-xs text-justify'><span className='font-semibold'>{t('left.paragraph.bold')}</span>{t('left.paragraph.normal')}</p>
                    </div>
                  </div>
                </div>
                <div className='w-full bg-white h-full overflow-hidden relative'>
                  <Menu mapMoveTo={mapMoveTo} />
                  <Map mapRef={mapRef} mapMoveTo={mapMoveTo} />
                </div>
              </div>
            </div>
            <Footer />
          </div>
      }
      <LanguageModal />
      {isVisible && <Modal />}
    </div>
  );
}

export default App;
