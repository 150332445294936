export const zonaHuayacanLinks = {
    en: {
        facebook: "https://www.facebook.com/profile.php?id=61570653095798",
        instagram: "https://www.instagram.com/zonahuayacan.us",
        tiktok: "https://www.tiktok.com/@zonahuayacanusa?_t=ZM-8smx7SCt6sH&_r=1",
        youtube: "https://www.youtube.com/@zonahuyacanusa"
    },
    es: {
        facebook: "https://www.facebook.com/zonahuayacan",
        instagram: "https://www.instagram.com/zonahuayacan/",
        tiktok: "https://www.tiktok.com/@zonahuayacan?_t=ZM-8smx4kgQ1Uj&_r=1",
        youtube: "https://www.youtube.com/@zonahuayacan"
    }
};