import { useEffect, useRef } from "react";
import { menuStore } from "../../store/menuStore";
import { useTranslation } from "react-i18next";
import { Facebook, Instagram, Tiktok, Youtube } from "@common/Icons";

const Menu = ({mapMoveTo}) => {
    const {activeIndex, places, isMenuVisible} = menuStore();
    const { toggleMenu, setActiveIndex } = menuStore();
    const [t] = useTranslation("global");
    const screen = useRef();
    useEffect(()=>{
        if(screen.current){
            if(isMenuVisible){
                setTimeout(()=>{
                    screen.current.classList.add('!-translate-x-0');
                },100);    
            }else{
                screen.current.classList.remove('!-translate-x-0');
            }
        }
    }, [isMenuVisible]);
    
    const mapGoTo = (long, lat, index) => {
        setActiveIndex(index)
        toggleMenu(false);
        mapMoveTo(long, lat);
    }
    //w-2/4 lg:w-2/4 xl:w-1/4
    return <>
        <div ref={ screen } className="w-full h-full z-[40] bg-black absolute flex flex-col justify-around items-center translate-x-full duration-500 ease-out transition-all">
            <div className="fixed top-0 left-0">
                <img src="/images/leaves.png" alt="leaves" className="w-28" />
            </div>
            <div className="fixed top-[20%] bottom-0 my-auto left-0 h-[50%]">
                <img src="/images/map-circle.png" alt="leaves" className="h-full" />
            </div>
            <div className="mt-16 mb-10">
                <h2 className="text-[#A78E64] mx-auto font-extrabold text-xl uppercase">{t("offcanvas_sidebar.title")}</h2>
            </div>
            <div className="h-full overflow-y-scroll pr-1 menu-scroll">
                <div className="container">
                {
                    places?.length && places.map((place) => {
                        return <div key={place.key} className="flex flex-col md:grid grid-cols-12 text-gray-50">
                            <div className="flex md:contents">
                                <div className="col-start-2 col-end-4 mr-2 relative">
                                    <div className="h-full w-6 flex items-center justify-center">
                                        <div className="h-full w-0.5 bg-white pointer-events-none"></div>
                                    </div>
                                    <div className={`absolute top-2/3 -mt-3 left-2 rounded-full ${activeIndex===place.key?'bg-[#A78E64] w-2 h-2':'bg-white w-2 h-2'} shadow text-center`}>
                                        <i className="fas fa-check-circle text-white"></i>
                                    </div>
                                </div>
                                <div className="col-start-4 col-end-12 my-4 w-full cursor-pointer" onClick={() => mapGoTo(place.location.long, place.location.lat, place.key)}>
                                    <h3 className={`font-semibold duration-100 ease-out transition-all ${activeIndex===place.key?'text-lg':'text-sm'}`}>
                                        <span className="text-[#9B8665] font-bold">{place.replace}</span> {place.name.replace(place.replace, '')}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    })
                }
                    
                </div>
            </div>
            <div className="mt-4">
                <p className="w-full text-center text-[#9B8665] font-bold">{t("footer.labels.follow")}</p>
                <div className='grid justify-center grid-cols-4 mx-auto gap-3 mt-3'>
                    <span className='bg-white rounded-full w-7 h-7 flex justify-end items-end md:mx-[inherit] mx-auto'>
                        <a href="https://www.facebook.com/OriginByWolf/" target="_blank" rel="noreferrer" className="relative top-0.5 left-0.5">
                            <Facebook className={"text-black"} />
                        </a>
                    </span>
                    <span className='bg-white w-7 h-7 flex items-center justify-center rounded-full md:mx-[inherit] mx-auto'>
                        <a href="https://www.instagram.com/originbywolf/" target="_blank" rel="noreferrer">
                            <Instagram className={"text-black"} />
                        </a>
                    </span>
                    <span className='bg-white w-7 h-7 flex items-center justify-center rounded-full md:mx-[inherit] mx-auto'>
                        <a href="https://www.tiktok.com/@originbywolf" target="_blank" rel="noreferrer">
                            <Tiktok className={"text-black"} />
                        </a>
                    </span>
                    <span className='bg-white w-7 h-7 flex items-center justify-center rounded-full md:mx-[inherit] mx-auto'>
                        <a href="https://www.youtube.com/@originbywolf1988" target="_blank" rel="noreferrer">
                            <Youtube className={"text-black"} />
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </>
}

export default Menu;